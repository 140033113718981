import React, { useCallback, useMemo } from 'react';
import { css } from '@emotion/core';
import { useTheme } from 'emotion-theming';
import { graphql, useStaticQuery } from 'gatsby';
import turf from 'turf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMapMarkerAlt,
  faSearch,
  faArrowDown,
} from '@fortawesome/pro-solid-svg-icons';
import { isSafari, isMobileSafari, isMacOs } from 'react-device-detect';

import mq from '../../../styles/mq';

import { DealerEntity } from '../../../types/dealer';
import { ThemeType } from '../../../styles/theme';

interface StaticQuery {
  allContentfulDealer: {
    edges: {
      node: DealerEntity;
    }[];
  };
}

const query = graphql`
  {
    allContentfulDealer {
      edges {
        node {
          name
          street
          number
          zip
          city
          country
          location {
            lat
            lon
          }
        }
      }
    }
  }
`;

interface SidebarProps {
  position: {
    latitude: number;
    longitude: number;
  };
  fieldValue: string;
  setFieldValue: (value: string) => void;
}

const Sidebar: React.FC<SidebarProps> = ({
  position,
  fieldValue,
  setFieldValue,
}) => {
  const { allContentfulDealer } = useStaticQuery<StaticQuery>(query);
  const { color } = useTheme<ThemeType>();

  const dealers = useMemo(
    () => allContentfulDealer.edges.map((edge) => edge.node),
    []
  );

  const dealersSortedByDistance = useMemo(
    () =>
      dealers
        .map((dealer) => {
          const distance = turf.distance(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            [position.longitude, position.latitude],
            [dealer.location.lon, dealer.location.lat]
          );
          return { ...dealer, distance };
        })
        .sort((a, b) => {
          if (a.distance > b.distance) {
            return 1;
          }
          if (a.distance < b.distance) {
            return -1;
          }
          return 0;
        }),
    [position]
  );

  const renderDealers = useCallback(
    () => (
      <ul
        css={css`
          overflow: ${isSafari || isMobileSafari || isMacOs
            ? `scroll`
            : `auto`};
          height: 400px;
          padding-bottom: 300px;
        `}
      >
        {dealersSortedByDistance.map((result, key) => (
          <li
            key={key}
            css={css`
              margin-bottom: 15px;

              &:last-of-type {
                margin-bottom: 0;
              }
            `}
          >
            <article
              css={css`
                background-color: ${color.white};
                border-radius: 5px;
                padding: 25px 35px;

                p {
                  color: #a1a1a1;
                  font-size: 16px;
                  font-weight: 500;
                }
              `}
            >
              <h2
                css={css`
                  color: #009896;
                  font-size: 24px;
                  font-weight: 900;
                  margin-bottom: 20px;
                `}
              >
                {result.name}
              </h2>
              <p>
                {result.street} {result.number}
              </p>
              <p>{result.city}</p>
              <p>{result.country}</p>
            </article>
          </li>
        ))}
      </ul>
    ),
    [dealersSortedByDistance, isSafari, isMobileSafari, isMobileSafari, isMacOs]
  );

  return (
    <aside
      css={css`
        background-color: ${color.primary};
        padding: 50px 25px;
        height: 100%;
        display: flex;
        flex-direction: column;

        ${mq(`lg`)} {
          padding: 50px 75px;
        }
      `}
    >
      <h1
        css={css`
          color: ${color.white};
          font-size: 30px;
          font-weight: 900;
          margin-bottom: 50px;
          max-width: 250px;

          ${mq(`md`)} {
            font-size: 50px;
            max-width: initial;
          }
        `}
      >
        Vous cherchez de la 3D ?
      </h1>
      <div>
        <div
          css={css`
            margin-bottom: 40px;
          `}
        >
          <div
            css={css`
              position: relative;
            `}
          >
            <FontAwesomeIcon
              icon={faMapMarkerAlt}
              color="#bdbdbd"
              css={css`
                top: 45%;
                transform: translateY(-50%);
                position: absolute;
                left: 30px;
              `}
            />
            <input
              value={fieldValue}
              onChange={(e) => setFieldValue(e.target.value)}
              css={css`
                appearance: none;
                border: 0;
                border-radius: 50px;
                padding: 22px 0 22px 75px;
                width: 100%;

                &::placeholder {
                  color: #bdbdbd;
                  font-size: 16px;
                  font-weight: 700;
                }
              `}
              placeholder="Code postal"
            />
            <button
              type="submit"
              css={css`
                appearance: none;
                border: 0;
                background-color: transparent;
                top: 47%;
                transform: translateY(-50%);
                position: absolute;
                right: 20px;
                width: 25px;
                height: 25px;
                cursor: pointer;
                font-size: 18px;
              `}
            >
              <FontAwesomeIcon icon={faSearch} color={color.primary} />
            </button>
          </div>
        </div>
      </div>
      <div
        css={css`
          position: relative;
          overflow: hidden;
        `}
      >
        <figure
          css={css`
            background-color: ${color.white};
            width: 50px;
            height: 50px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 1001;
            box-shadow: 0 0 10px hsla(0, 0%, 0%, 0.3);

            svg {
              width: 20px;
            }
          `}
        >
          <FontAwesomeIcon icon={faArrowDown} color={color.primary} />
        </figure>
        <p
          css={css`
            color: ${color.white};
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 20px;
          `}
        >
          {dealers.length} résultats
        </p>
        {renderDealers()}
      </div>
    </aside>
  );
};

export default React.memo(Sidebar);
