import React, {
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { css } from '@emotion/core';
import { useTheme } from 'emotion-theming';
import { graphql, useStaticQuery } from 'gatsby';
import ReactMapGL, { Marker } from 'react-map-gl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons';

import Layout from '../components/layout';
import Sidebar from '../components/pages/contact/sidebar';

import mq from '../styles/mq';

import { DealerEntity } from '../types/dealer';
import { ThemeType } from '../styles/theme';
import SEO from '../components/helpers/seo';

interface ViewportProps {
  width: string | number;
  height: string | number;
  latitude: number;
  longitude: number;
  zoom: number;
}

interface StaticQuery {
  allContentfulDealer: {
    edges: {
      node: DealerEntity;
    }[];
  };
}

const query = graphql`
  {
    allContentfulDealer {
      edges {
        node {
          id
          name
          street
          number
          zip
          city
          country
          location {
            lat
            lon
          }
        }
      }
    }
  }
`;

const PointsDeVente: React.FC = () => {
  const { color } = useTheme<ThemeType>();
  const { allContentfulDealer } = useStaticQuery<StaticQuery>(query);
  const [position, setPosition] = useState<{
    latitude: number;
    longitude: number;
  }>({
    latitude: 0,
    longitude: 0,
  });
  const [fieldValue, setFieldValue] = useState<string>(``);

  const [viewport, setViewport] = useState<ViewportProps>({
    width: `100%`,
    height: `100%`,
    latitude: 50.5799964,
    longitude: 4.7129828,
    zoom: 10,
  });

  const dealers = useMemo(
    () => allContentfulDealer.edges.map((edge) => edge.node),
    []
  );

  const renderMarkers = useCallback(
    () =>
      dealers.map((dealer) => (
        <Marker
          key={dealer.id}
          latitude={dealer.location.lat}
          longitude={dealer.location.lon}
          css={css`
            width: 1px;
            height: 1px;
          `}
        >
          <FontAwesomeIcon
            icon={faMapMarkerAlt}
            size="2x"
            color={color.primary}
          />
        </Marker>
      )),
    [dealers]
  );

  const localized = useRef<boolean>(false);

  const locateUser = useCallback(() => {
    if (!localized.current && typeof navigator !== 'undefined') {
      navigator.geolocation.getCurrentPosition((position) => {
        setPosition({
          longitude: position.coords.longitude,
          latitude: position.coords.latitude,
        });
      });
      localized.current = true;
    }
  }, []);

  useEffect(() => {
    locateUser();
  }, []);

  const onSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();

      try {
        const res = await fetch(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/be-${fieldValue}.json?access_token=${process.env.GATSBY_MAPBOX_ACCESS_TOKEN}&country=be&type=postcode`
        );
        const data = await res.json();
        if (data?.features?.length > 0) {
          setPosition({
            longitude: data?.features?.[0]?.geometry?.coordinates?.[0],
            latitude: data?.features?.[0]?.geometry?.coordinates?.[1],
          });
          setViewport((viewport) => ({
            ...viewport,
            longitude: data?.features?.[0]?.geometry?.coordinates?.[0],
            latitude: data?.features?.[0]?.geometry?.coordinates?.[1],
          }));
          setFieldValue(``);
        }
      } catch (e) {
        console.error(e);
      }
    },
    [fieldValue]
  );

  return (
    <Layout>
      <SEO
        title="Nos points de vente"
        description="Découvrez l'ensemble de nos points de vente."
      />
      <section
        css={css`
          padding: 100px 0 0;

          ${mq(`lg`)} {
            padding: 150px 0 0;
            height: 100vh;
            display: flex;
          }
        `}
      >
        <section
          css={css`
            height: 500px;

            ${mq(`lg`)} {
              height: auto;
              flex: 1;
            }
          `}
        >
          <ReactMapGL
            {...viewport}
            mapboxApiAccessToken={process.env.GATSBY_MAPBOX_ACCESS_TOKEN}
            onViewportChange={(v) => {
              setViewport({
                ...v,
                width: `100%`,
                height: `100%`,
              });
            }}
          >
            {renderMarkers()}
          </ReactMapGL>
        </section>
        <form
          onSubmit={onSubmit}
          css={css`
            height: 750px;

            ${mq(`lg`)} {
              max-width: 500px;
              height: auto;
            }
          `}
        >
          <Sidebar
            position={position}
            fieldValue={fieldValue}
            setFieldValue={setFieldValue}
          />
        </form>
      </section>
    </Layout>
  );
};

export default PointsDeVente;
